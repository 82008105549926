import { DateTime } from 'luxon';

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const validate = (dayToValidate: string, monthToValidate: string, yearToValidate: string, allowBlank: boolean) => {
    if (dayToValidate === "" && monthToValidate === "" && yearToValidate === "") {
        return allowBlank;
    }
    if (!allowBlank && (dayToValidate === "" || monthToValidate === "" || yearToValidate === "")) {
        return false;
    }
    const yearInt = parseInt(yearToValidate, 10);
    const monthInt = parseInt(monthToValidate, 10);
    const dayInt = parseInt(dayToValidate, 10);
    if (isNaN(yearInt) || isNaN(monthInt) || isNaN(dayInt)) {
        return false;
    }
    const dateToTest = DateTime.local(yearInt, monthInt, dayInt);
    return dateToTest.isValid;
};

export const convertTimeSegment = (originalValue: number | string) => {
    if (typeof originalValue === "string") {
        originalValue = parseInt(originalValue, 10);
    }
    return DateTime.fromMillis(originalValue).toFormat("HH:mm");
};

/**
 * Create a readable view of the amount of time represented by a number of seconds.
 * @param time {number} time in seconds.
 * @returns readable text.
 */
export const formatTime = (time: number) => {
    if (time < 60) {
        return `${time} seconds`;
    } else if (time < 3600) {
        const minutesAgo = Math.floor(time / 60);
        return `${minutesAgo} minutes`;
    } else if (time < 86400) {
        const hoursAgo = Math.floor(time / 3600);
        return `${hoursAgo} hours`;
    } else {
        const daysAgo = Math.floor(time / 86400);
        return `${daysAgo} days`;
    }
};

export const getDifference = (date1: number, date2: number) => {
    if (!date1 || !date2) {
        return "Unknown";
    }
    const diff = ((date1 - date2) / 1000) / 60;
    return Math.ceil(diff) + " minutes";
};

export default function dateConverter(originalValue: number | string | Date, showTime?: boolean, emptyString?: string): string {
    if (!originalValue) {
        return emptyString || "N/A";
    }

    if (typeof originalValue === "string") {
        originalValue = parseInt(originalValue, 10);
        if (isNaN(originalValue)) {
            return emptyString || "";
        }
    }
    if (typeof originalValue === "object") {
        originalValue = originalValue.valueOf();
    }
    if (showTime) {
        return DateTime.fromMillis(originalValue).toFormat("dd-MMM-yyyy HH:mm");
    }
    return DateTime.fromMillis(originalValue).toFormat("dd-MMM-yyyy");
};
